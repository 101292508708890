.ArticleStub {
    width: 100%;
    max-width: var(--max-width);
    padding-top: 0;
    padding-bottom: 0;
    /* margin-bottom: 20px; */

    /* background: red; */





}

.ArticleStub > .TileListItemContent {
    display: grid;

    grid-template-columns: 1fr repeat(10, 2fr) 1fr;
    grid-template-rows:  repeat(8, auto);
    padding-bottom: var(--gutter-size);



    border-radius: 6px;
    /* box-shadow: var(--product-shadow); */
    margin: 10px;
    /* border: 1px solid var(--main-fg-color); */
    box-sizing: border-box;

    /* background: blue; */



}

.ArticleStub > * > .icon-pin {
    grid-column: 11;
    grid-row: 1;
    font-size: var(--font-size-large);
    text-align: right;
    align-self: top;
    margin-top: 12px;
}

.ArticleStub > * > h2 {
    grid-column: 2 / span 8;
    grid-row: 1;
    z-index: 10;
    /* margin-top: var(--margin-size); */
    margin: var(--gutter-size) 0  var(--gutter-size) 0;
    font-family: var(--font-normal);
    font-size: var(--font-size-xlarge);
    font-weight: 400;
    line-height: var(--font-size-xlarge);
    text-transform: unset;

    align-self: center;

}

.ArticleStub >  * > h3 {
    grid-column: 10 / span 3;
    grid-row: 1;
    z-index: 10;
    line-height: var(--font-size-xlarge);
    /* margin-top: var(--gutter-size); */
    align-self: center;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    display: none;

    
}

.ArticleStub >  * > h3 > span {
    width: auto;
    /* border: 1px solid var(--main-accent-color); */
    background: var(--secondary-fg-color);
    padding: 6px;
    border-radius: 3px;

    box-shadow: var(--button-shadow);
}

.ArticleStub > * > .published-date {
    grid-column: 2 / span 8;
    grid-row: 8;
    color: var(--secondary-font-color);
    align-self: center;
    /* font-family: var(--font-technical); */
    font-size: var(--font-size-small);
}



.ArticleStub >  * > .placeholder-img,
.ArticleStub > * >  img

{
    grid-column: 1 / span 12;
    grid-row: 2 / span 3;
    /* background: var(--secondary-bg-color); */
    /* background-image: 
            linear-gradient( 180deg, transparent 94%, var(--secondary-bg-color) 95%),
            radial-gradient( circle, transparent 30%, var(--main-bg-color) 85%),
            linear-gradient( 180deg, var(--main-bg-color) 30%,  var(--secondary-bg-color) 70%, var(--secondary-fg-color) 90%)
            ; */
    object-fit: scale-down;
    /* min-height: 340px; */
    z-index: 9;
    /* height: 100%; */
    width:  100% ;
    max-height: 420px;
    border-radius: 3px;
    justify-self: center;

    /* box-shadow:  inset 0px 20px 40px var(--main-bg-color), inset 0px -20px 40px var(--secondary-bg-color); */
    animation: var(--anim-fade-in);
}

/* video, */
.video-content {
    grid-column: 1 / span 12;
    grid-row: 3 / span 3;
    height: auto;
    width: 100%;
    /* height: 200px; */

    /* height: calc( 56.25% * content-width); */
}



.ArticleStub > * >  .slug {
    grid-column: 2 /span 10;
    grid-row: 7;
    /* max-width: 450px; */

}

.ArticleStub > * >  .byline,
.ArticleStub > * >  a,
.ArticleStub > * >  link,
.ArticleStub > * >  .ButtonText {
    grid-column: 10 /span 2;
    grid-row: 8;
    justify-self: right;
    align-self: center;
    transform: translateX(var(--gutter-size));

}

.ArticleStub > * > .social-reacts-wrapper {
    display: inline-block;
    background: red;
    /* height: var(--font-size-xlarge); */
    /* width: 100%; */
    grid-column: 10 /span 2;
    grid-row: 4 / span 1;
    justify-self: right;
    align-self: flex-end;
}
.ArticleStub > * > .social-reacts-wrapper:hover {
    /* background: blue; */
}

.ArticleStub > * > * > .social-reacts {
    background: blue;
    --square-sizing: 32px;
    display: grid;
    grid-template-rows: var(--square-sizing);

    grid-template-columns: 1fr 1fr ;
    height: var(--square-sizing);

    font-size: var(--font-size-xmedium);
    /* line-height: var(--font-size-xmedium); */

    align-items: center;
    justify-items: right;
    color: var(--secondary-font-color);
    /* padding: 6px; */
    
}

.ArticleStub > * > * > .social-reacts > i {
    display: block;

    /* cursor: pointer; */

    text-align: center;
    line-height: var(--square-sizing);
    /* padding-left: 6px; */
    font-size: var(--font-size-xlarge);
    height: var(--square-sizing);
    width: var(--square-sizing);
    border-radius: var(--square-sizing);
    background: green;
    /* transform: translateY(6px); */
}

.ArticleStub > * > * > .social-reacts > i:hover {
    background:gray;
    
}
