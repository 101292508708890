a.event-list-item:link,
a.event-list-item:visited,
a.event-list-item:active,
a.event-list-item:hover  {
  text-decoration: none;
  color: inherit;
  transition: all .1s ease-out;

  transform-origin: left;
  cursor: pointer;
}


a.event-list-item:hover, a.event-list-item:active{
  /* background:  var(--secondary-bg-color); */
  color: var(--secondary-font-color);
  /* transform: rotateY(5deg); */
}


.EventListItemTags{
  grid-row: 6;
  grid-column: 3 / span 6;

  display: none;

}



.event-list-item {
    z-index: 1;

    grid-column: 1 / span 12;
    margin-bottom: 2px;

    display: grid;


    /* interaction stuff */
    cursor: default;
    overflow: hidden;
    animation: var(--anim-fade-in);    

}


.event-item-bg-effects {
    grid-column: 1 / span 12;
    padding-top: 16px;
    padding-bottom: 20px;
}



.event-item-data {
    grid-column: 2 / span 10;
    display: grid;
    grid-template-rows: repeat(6, minmax(0, auto)) ;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 6px;
    row-gap: 2px;
    /* height: 60px; */
    overflow: hidden;
    /* border-radius: 5px; */

    padding: 10px;
    /* padding-bottom: 30px; */
    border-bottom: 1px solid var(--main-fg-color);

    /* background: linear-gradient(145deg, #19272a, #152023); */
    /* box-shadow: var(--button-shadow); */
                
}




.eventlistItemIcon {
  grid-row: 1;
  grid-column: 1;
  /* display: none; */
}

.eventListItemIconContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-row: 2 / span 2;
  grid-column: 1 / span 1;
  align-self: center;
  justify-self: center;
  /* width: 100%; */
  /* max-width: 64px; */
  /* height: 100%; */
  /* max-height: 64px; */
  /* padding: 6px; */
}


.item-operator {
  grid-row: 2;
  grid-column: 2 / span 8;
  padding-left: var(--gutter-size);
  font-size: var(--font-size-large);
}

.item-location {
  grid-row: 4;
  grid-column: 2 / span 8;
  padding-left: var(--gutter-size);

}

.item-name {
  grid-row: 3;
  grid-column: 2 / span 6;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: var(--gutter-size);
  /* display: none; */
}

.item-time{
  grid-row: 1;
  grid-column: 1 / span 6;
  align-self: center;
  font-size: var(--font-size-small);
  color: var(--secondary-font-color);
  margin-bottom: var(--gutter-size);
}

.item-time > .accent {
  font-weight: 400;
  font-size: var(--font-size-small);
  color: var(--secondary-font-color);
}

.eventListItemTimeArrow{
  margin-left: 6px;
  margin-right: 6px;
}



.item-link{
  grid-row: 2;
  grid-column: 10 / span 1;
  justify-self: center;
  align-self: center;
  font-size: var(--font-size-large);
  color: var(--secondary-font-color);
}


.item-price{
  grid-row: 5;
  grid-column: 9 / span 2;
  justify-self: right;
  display: none;
}


.item-indicator{
  grid-column: 1;
}


/*  PROMOTED STUFF */

/* .event-list-item-promo > .event-item-data > .eventListItemIconContainer > .eventlistItemIcon, */
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-operator ,
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-name,
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-location,
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-time,
.event-list-item-promo > .event-item-bg-effects  > .event-item-data >  *
{ display: initial; }

.event-list-item-promo {
  background: linear-gradient( 285deg, var(--main-bg-color),  var(--secondary-bg-color));
  background-size: cover;
  height: auto;
  border: 1px solid var(--main-bg-color);
  box-sizing: border-box;
  /* box-shadow: var(--product-shadow); */
}

.event-list-item-promo:first-of-type {
   margin-top: var(--margin-size);
}


.event-list-item-promo > .event-item-bg-effects  > .event-item-data {
    height: 120px;
    border: none;
}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .eventListItemIconContainer {
  /* grid-row: 1 / span 2; */
}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-location {
  /* grid-row: 1;
  grid-column: 2 / span 7; */
}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-operator  {
  /* grid-row: 2;
  grid-column: 2 / span 7; */
  white-space: nowrap;
  text-overflow: ellipsis;
 }
 
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-name  {
  /* grid-row: 5 / span 2;
  grid-column: 1 / span 8; */
  font-size: var(--font-size-large);
  line-height: var(--font-size-large);
  height: auto;
  font-weight: 400;
  white-space: none;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left: 0; */
 }


.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-time {
  /* grid-row: 4;
  grid-column: 1 / span 8; */
}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .EventListItemTags {
  /* grid-row: 8;
  grid-column: 1 / span 8; */
  align-self: center;
  display: none;

}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-price {
  /* grid-row: 8;
  grid-column: 9 / span 2; */
  display: none;

}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-link {
  grid-row: 1 / span 8;
  color: var(--main-accent-color);
}


.featured {
  /* height: auto; */

  /* background-position-y: 50px; */
  background-size: cover;
  background-position: center;
}



.event-list-item-promo > .event-item-bg-effects , 
.featured > .event-item-bg-effects {

  background: 
    linear-gradient( 180deg, transparent 94%, var(--main-bg-color) 95%),
    /* radial-gradient( circle, transparent 50%, var(--main-bg-color) 90%), */
    linear-gradient( 270deg, transparent 0%, var(--main-bg-color-60) 75%, var(--main-bg-color) 100%)
    ;
  background-size: cover;
  background-position: center;
  /* border: 3px solid var(--main-accent-color ) */

}






.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-link {
  text-shadow: var(--shadow-blend-bg) ;
  /* font-size: var(--font-size-xxlarge); */
  align-self: center;
  /* grid-row: 5 / span 2; */
}


.event-list-item-promo > .event-item-bg-effects  > .event-item-data {

   background:
  linear-gradient( 90deg, var(--main-accent-color) 0%,  var(--main-accent-color) .5%, transparent .5%);
  /* border: 1px solid var(--main-accent-color); */
  /* height: 325px; */
  /* border-radius: 6px; */
  /* box-shadow: 6px 6px 12px var(--secondary-bg-color), 
              -6px -6px 12px var(--main-fg-color); */
}



/* .featured > .event-item-data >, */
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-operator,
.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-location,
.event-list-item-promo> .event-item-bg-effects  > .event-item-data > .item-name {
  text-shadow: var(--text-shadow-large), var(--text-shadow-small) ;
}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-name {
  text-shadow: var(--shadow-blend-bg) ;
  /* grid-row: 5 / span 2;
  grid-column: 1 / span 12; */
  /* font-size: var(--font-size-xlarge);
  line-height: var(--font-size-xxlarge); */
  
  font-weight: 100;
  /* align-self: center; */
  justify-self: left;
  /* white-space: unset; */
  text-overflow: normal;
  


}

.event-list-item-promo > .event-item-bg-effects  > .event-item-data > .item-time {
  /* grid-row: 7;
  grid-column: 1 / span 6; */
  /* align-self: flex-end; */
  /* display: none; */
  text-shadow: var(--shadow-blend-bg2), var(--shadow-blend-bg2) ;


}

.event-list-item-promo> .event-item-bg-effects  > .event-item-data > .item-operator {
  text-shadow: var(--shadow-blend-bg) ;
  /* align-self: flex-end; */
  /* grid-column: 1 / span 8;
  grid-row: 7 / span 1; */
  /* padding-left: 0; */
}




.featured > .event-item-bg-effects  > .event-item-data > .eventListItemIconContainer {
  /* grid-row: 1/ span 1; */
  align-self: center;
}


.featured > .event-item-bg-effects  > .event-item-data > .item-location {
  align-self: center;
  justify-self: right;
  /* grid-column: 4 / span 7; */
}




.overlayCancelled {
  grid-row: 1 / span 8;
  grid-column: 1/ span 10;
  text-align: center;
  align-self: center;
  justify-self: right;
  z-index: 120;
  font-size: var(--font-size-small);


}

.overlayCancelled > span {
  font-size: var(--font-size-small);
  text-transform: uppercase;
  /* color: var(--main-bg-color); */
  padding: var(--gutter-size);
  border-radius: 6px;
  border: 1px solid var(--secondary-accent-color); 
  background: var(--main-bg-color); 
  font-weight: 600;

}

.cancelled > * > * > .fade {
  opacity: .3;

}