.LongformBody {
    
    grid-column: 2 / span 10 ;
    justify-self: center;
    padding-top: var(--header-height);
}

.LongformViewComponent > .content-container { 
    justify-self: center;
    justify-items: center;
    justify-content: center;
}

.LongformViewComponent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100%;
}