.FilterRegionContainer {

}

.FilterRegionTitle {
    text-align: left;
}

.FilterRegionSelect {
    font-family: var(--font-regular);
    color: var(--main-font-color);
    font-weight: lighter;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    line-height: 1.6em;

    background: var(--main-bg-color);
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    /* width: 100%; */
    min-width: 310px;
}

.FilterRegionSelect option {
    /* background: red; */
    text-transform: none;
    font-weight: lighter;
    font-size: var(--font-size-large);
    border: none;
    padding: 6px;
}

.FilterRegionSelect option:hover,
.FilterRegionSelect option:checked,
.FilterRegionSelect option:focus,
.FilterRegionSelect option:active {
    color: var(--main-bg-color);
    background: var(--main-accent-color);
}