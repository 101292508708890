.EventFiltersContainer {
    /* box-shadow: 0px 15px var(--row-size) var(--main-bg-color); */
    z-index: 301;


    position: fixed;
    top: 0;
    left: 0;
    background: var(--main-bg-color);
    width: 100%;
    height: calc(var(--row-size) * 2 + var(--gutter-size));
    display: grid;

    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;

    /* box-shadow: 0px 15px 25px var(--main-bg-color); */


    
    /* transition: all ease-in-out .2s; */

}


  .EventFiltersContainer > .content > h1 {
    grid-row: 1 / span 1;  
    grid-column: 2 / span 2;
    /* font-size: 16px;
    line-height: 16px; */
    line-height: 0;
    padding:0;
    margin: 0;
    align-self: flex-end;
    justify-self: left;
  }

  .EventFiltersContainer > .content > h2 {
    opacity: 0;
    grid-row: 1 / span 1;  
    grid-column: 5 / span 6;
    font-size: var(--font-size-xmedium);
    font-family: var(--font-alt);
    text-transform: uppercase;
    /* line-height: 16px; */
    line-height: 0;
    padding:0;
    margin: 0;
    align-self: flex-end;
    justify-self: right;
  }
  

  .EventFiltersContainer  > .content > .FilterViewBar {
    grid-row: 2 / span 1;
    grid-column: 2 / span 10;
    align-self: center;

    /* grid-column: 2 / span 10;
    align-self: left; */
  }


  .EventFiltersContainer > .content {
    max-width: var(--max-app-width);
    min-width: var(--min-width);
    width: 100%;
    /* height: var(--header-height); */


    display: grid;
    grid-template-rows: repeat(1, var(--row-size));
    grid-template-columns: 1fr repeat(10, 2fr) 1fr;
    grid-gap: 6px;

    -moz-box-shadow:    inset 0 3px 0 var(--main-accent-color);
    -webkit-box-shadow: inset 0 3px 0 var(--main-accent-color);
    box-shadow:         inset 0 3px 0 var(--main-accent-color);
}

.EventFiltersContainer > .content > .FilterRegionContainer {
    grid-row: 2;
    grid-column: 2 / span 10;
    align-self: left;
}


.settings-container {
    cursor: pointer;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;
    grid-row: 1 / span 2;
    grid-column: 11;
    align-self: center;
    justify-self: flex-end;
    width: 32px;
    height: 32px;
    border-radius: 60px;
    color: var(--secondary-font-color);
    font-size: var(--font-size-xlarge);
    transform: translateY(-8px);
}

.EventFiltersContainer > .content > .settings {




}
