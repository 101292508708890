/* VARIABLES FOR EASIER MAINTENANCE */

:root {
  /* Main theme */
  --main-bg-color: hsla(191,26%,12%,1);
  --main-bg-color-60: hsla(191,26%,12%,.6);
  --secondary-bg-color: hsla(195, 24%, 10%, 1);
  --main-fg-color: hsla(192,15%,19%, 1);
  --secondary-fg-color: hsla(189, 8%, 32%, 1);
  --main-font-color: hsla(0, 0%, 100%, 1);
  --secondary-font-color: hsla(0,1%, 72%, 1);

  --shadow-color: hsla(0,0%,0%, .2);
  --shadow-color-heavy: hsla(0,0%,0%, .7);

    /* Accents */
    --main-accent-color: hsla(41, 100%, 53%, 1);
    --secondary-accent-color: hsla(0, 100%, 66%, 1);
    --tertiary-accent-color: hsla(207, 90%, 54%, 1);
    --four-accent-color: hsla(313, 95%, 47%, 1);

    
/* TODO - isolate this for dark/light modes */


/* DUSK THEME */
  /* --main-bg-color: #000022;
  --secondary-bg-color: #000022;
  --main-fg-color: #DE3C4B;
  --secondary-fg-color: #C42847;
  --main-font-color: #FBF5F3;
  --secondary-font-color: rgb(170, 173, 166);
  --shadow-color: #000022;

  --main-accent-color:#E28412;
  --secondary-accent-color: #fff;
  --tertiary-accent-color: #2196F3; */

  /* SEASON ONE */
  --main-bg-color: #11161A;
  --secondary-bg-color: #0D1113;
  --main-fg-color: rgb(51, 50, 45);
  --secondary-fg-color: #4F4E49;
  --main-font-color: rgb(248, 250, 242);
  --secondary-font-color: rgb(211, 211, 194);
  


  /* --main-accent-color:#EDE100;
  --secondary-accent-color: #00AD17;
  --tertiary-accent-color: #2196F3; */






  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-xmedium: 16px;
  --font-size-large: 18px;
  --font-size-xlarge: 24px;
  --font-size-xxlarge: 38px;

  --line-height-normal: 18px;
  --line-height-medium: 26px;

  --font-technical: 'Roboto Mono', monospace;
  --font-regular: 'Roboto', sans-serif;
  --font-alt: 'Roboto Condensed', sans-serif;

  --card-width: 135px;

  --row-size: 42px;
  --gutter-size: 12px;
  --padding-size: 6px;
  --margin-size: 24px;
  --navbar-height: 78px;
  --header-height: 192px;
  --header-height-large: 375px;


  /* Instagram has a max witdh of 600px for content, 975 for top bar content */
  /* Instagram top bar is 77px high */
  --max-app-width: 917px;
  --max-width: 600px;
  --min-width: 320px;

  --min-width: 292px;

  --fake-shadow: var(--shadow-color-heavy);
  --button-shadow: 0px 5px 2px var(--shadow-color);
  --product-shadow: 0px 8px 8px var(--shadow-color), 0px 5px 5px var(--shadow-color);
  --text-shadow-large: 0px 5px 5px var(--shadow-color-heavy), 0px 15px 15px var(--shadow-color);
  --text-shadow-small: 0px 5px 5px var(--shadow-color-heavy), 0px 2px 2px var(--shadow-color);

  --shadow-blend-bg: 0 0 30px var(--main-bg-color), 0 0 10px var(--main-bg-color), 0 0 5px var(--main-bg-color);
  --shadow-blend-bg2: 0 0 15px var(--secondary-bg-color);
  /* ANIMATION TEMPLATES */
  --anim-slide-left: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s 1 backwards slideleft;
  --anim-slide-right: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s 1 backwards slideright;
  --anim-slide-down: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s 1 backwards slidedown;
  --anim-slide-up: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s 1 backwards slideup;
  --anim-fade-in: 0.2s ease-in-out 0.1s 1 backwards fadein;
  -webkit-tap-highlight-color: transparent;
}





@keyframes fadein {
  from  {   opacity: 0  }
  to    {   opacity: 1  }
}

@keyframes slideleft {
  from  {   transform: translateX(20%);
            opacity: 0; }
  to    {   transform: translateX(0);
            opacity: 1; }
}

@keyframes slideright {
  from  {   transform: translateX(-20%);
            opacity: 0; }
  to    {   transform: translateX(0);
            opacity: 1; }
}

@keyframes slidedown {
  from  {   transform: translateY(-20%);
            opacity: 0; }
  to    {   transform: translateY(0);
            opacity: 1; }
}

@keyframes slideup {
  from  {   transform: translateY(20%);
            opacity: 0; }
  to    {   transform: translateY(0);
            opacity: 1; }
}



/* USED TO DYNAMICALLY APPLY STYLES IN REACT */

.small    {  font-size: var(--font-size-small);   }
.medium   {  font-size: var(--font-size-medium);  }
.xmedium  {  font-size: var(--font-size-xmedium); }
.large    {  font-size: var(--font-size-large);   }
.xlarge   {  font-size: var(--font-size-xlarge);  }
.xxlarge  {  font-size: var(--font-size-xxlarge); }

.font-technical { font-family: var(--font-technical); }
.font-regular   { font-family: var(--font-regular);   }
.font-alt       { font-family: var(--font-alt);       }

.light    { font-weight: 100;     }
.regular  { font-weight: normal;  }
.medium-w   { font-weight: medium;  }
.bold     { font-weight: bold;    }
.heavy    { font-weight: 900;     }

.primary    { color: var(--main-font-color);        }
.accent     { color: var(--main-accent-color);      }
.secondary  { color: var(--secondary-font-color);   }
.warn       { color: var(--secondary-accent-color); }

.upper    { text-transform: uppercase;  }

.spacing-normal { line-height: 18px; }
.spacing-medium { line-height: 20px; }
.spacing-large  { line-height: 24px; }

.list-plain { list-style: none; margin: 0; padding: 0;}

.inline-block { display: inline; }


.foreground { background-color: var(--main-fg-color); }


.offset-header        {  margin-top: var(--header-height); }
.offset-header-larger {  margin-top: calc(var(--header-height-large)); }
.offset-navbar        {  margin-bottom: var(--navbar-height) }


.product-small  {width: 90px;}


.tag {
  padding: 2px 8px 2px 0px;

  margin-right: 4px;

  /* border: 1px solid var(--secondary-fg-color); */
  /* background: var(--main-fg-color) */
}

.tag.warn {
  font-weight: 600;
  /* color: var(--main-font-color); */
}

.border-bottom  { border-bottom: 1px solid var(--main-fg-color); }

html {
  font-size: 100%;
  background: var(--main-bg-color);
}

body {
  margin: 0;
  font-family:  var(--font-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem;
  font-weight: 400;
  text-emphasis: none;
  line-height: var(--line-height-normal);
  

 
}

body {
}

#app {
  transition-property: filter;
  transition: .1s ease-in-out;
  overflow-x: hidden;



  
}


#app.modalactive {
  position: fixed;
  overflow-y: hidden;
}

#app.modalactive > .blurrable {
  /* filter: blur(5px); */
  opacity: .2;
  transition: all .1s linear;
  padding:0;
  /* margin-left: 12px;  */
  overflow-x: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p, li {
  font-family: var(--font-regular);
  font-size: var(--font-size-medium);
  color: var(--secondary-font-color);
  line-height: var(--line-height-medium);
  font-weight: 400;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: var(--font-regular);
  color: var(--main-font-color);

  font-weight: 300;
  font-size: 1.75em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 1.6em;
  text-align: left;

}

h2
 {
  font-family: var(--font-technical);
  color: var(--main-font-color);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-large);
  line-height: var(--line-height-normal);
  /* identical to box height, or 112% */
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

h3 ,
.markdown-content h1 ,
.markdown-content h2 ,
.markdown-content h3  
 {
  font-family: var(--font-alt);
  color: var(--main-font-color);
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-xmedium);
  line-height: var(--line-height-normal);
  margin: 0;

  text-transform: uppercase;
  /* identical to box height, or 129% */
}


#root {
  display: grid;
  grid-template-columns: 100%;
  align-content: center;
  align-items: center;
  margin: 0 auto  0 auto;
  justify-items: center;

  /* Main colours */
  
  /* background-position: ; */
  z-index: 1;
  color: var(--main-font-color);
}

#root:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: linear-gradient(0180deg , var(--main-bg-color) var(--header-height-large),   var(--main-fg-color) 300%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.anchor {
  display: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

div {
  margin: 0; padding: 0;
}

.app-container {
  display: grid;
  grid-template-columns: 100%;
  align-content: center;
  align-items: center;
  /* justify-items: center; */

  min-width: var(--min-width);
  width:100%;
  max-width: var(--max-app-width);
  padding: 0;
  margin: 0;
}



.content-container {
    justify-self: center;
    align-content: center;
    align-items: center;
    width: 100%;
}

.content-container
, .twelve-columns {
  display: grid;
  grid-template-columns:  1fr repeat(10, 3fr) 1fr;
  grid-gap: 6px;
  /* justify-self: center; */
  align-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  
  max-width: var(--max-width);
  -webkit-overflow-scrolling: touch;
}

.auto-rows-42 {
  grid-auto-rows: minmax(45px,auto);
  gap: var(--gutter-size);
}

.wide,
.TileListItemContent.wide {
  grid-column: 1 / span 12;
  /* background: red; */
}



.hidden {
  display: none;
}


Button {
  font-family: var(--font-alt);
  size: var(--font-size-medium);
  color: var(--main-accent-color);
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: none;
}

/* CONTAINERS  */
.width-container {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  width: 100%;
  max-width: 720px;
  min-width: 375px;
}



.content-wrapper {
  grid-column: 1 / span 12;
}

.view-header {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--main-bg-color);
  width: 100%;
  height: 192px;
  display: grid;
  grid-template-rows: repeat(4, 42px);
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 6px;
}

.view-header-large{
  height: 375px;
  position: inherit;
  grid-template-rows: repeat(8, 42px);
}

.logo-top-left {
  grid-row: 1 / span 2;
  grid-column: 2 / span 4;
  align-self: center;
  justify-self: left;
  height: 15px;
}

.cta-top-right {
  grid-row: 1 / span 2;
  grid-column: 8 / span 4;
  align-self: center;
  justify-self: right;
  height: 15px;
}

.cta-top-left {
  grid-row: 1 / span 2;
  grid-column: 2 / span 4;
  align-self: center;
  justify-self: left;
  height: 15px;
}


.view-title {
  grid-row: 3;
  grid-column: 2 / span 10;
  align-self: center;
}

.view-title-large {
  grid-row: 5 / span 2;
  grid-column: 2/span 9;
  font-size: 2.8em;
  line-height: 36px;
  text-align: left;
}


.row-6 {
  grid-row: 6;
}

.col-8 {
  grid-column: 8;
}

.span-4 {
  column-span: 4;
}

.inline {
  display: inline-block;
}

.view-options ul {
  list-style: none;
}

.view-options ul li {
  display: inline-block;
}



.detail {
  font-family: 'Roboto', sans-serif;
  color: var(--secondary-font-color);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

}

.header-back {
  grid-row: 1/ span 2;
  grid-column: 1 / span 3;
} 


.label-mono-value{
  font-family: 'Roboto Mono', monospace;
  color: var(--main-font-color);
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  letter-spacing: 0.03em;
}

.label-tag {
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--secondary-font-color);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

.label-category {
  height: 42px;
  line-height: 42px;
}

.button-link {
  font-family: 'Roboto Condensed', sans-serif;
  color: var(--secondary-font-color);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-decoration: none;
  cursor: pointer;
}

.button-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-decoration: none;
  cursor: pointer;
}

.nav-links {
  /* padding: 12px; */
  /* padding-left: 14px;
  padding-right: 14px; */
}

.nav-links-view {
  padding-left: 0;

}

.nav-links-left {
  grid-column: 2 / span 6;
  padding-left: 0;
}

.nav-links-right {
  grid-column: 8 / span 4;
  text-align: right;
  padding-right: 0;
}

.current,
.action {
  color: var(--main-accent-color);
  font-style: normal;
  font-weight: bold;
}


.pad-small {
  padding-left: 6px;
}


::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--secondary-font-color);
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
