.TextBlockDisplayContent > * {
    padding-top: calc(var(--row-size)/2);
    color: var(--secondary-font-color);
    margin: 0;
    padding-top: var(--padding-size)

}


.TextBlockDisplayContent > h2,
.TextBlockDisplayContent > h3 {
    margin-top: var(--gutter-size);
    padding-top: var(--gutter-size);
}

.TextBlockDisplayContent > p > em {
    color: var(--secondary-accent-color)
}

.TextBlockDisplayContent > a,
.TextBlockDisplayContent > p > a {
    color: var(--main-accent-color)
}