.EventListContent {
    margin-top: calc(var(--row-size) * 2 + var(--gutter-size)) ;
    padding-bottom: calc(var(--navbar-height) / 2);
    overflow: hidden;
    animation: var(--anim-slide-left);

    display: grid;
    grid-template-columns: 100%;
    /* grid-template-rows: auto; */
    row-gap: var(--margin-size);


}



.EventListDateHeader {
    grid-column: 2 / span 10;
    margin-top: var(--margin-size);
    margin-bottom: 0;
    text-transform: ;

}
  

.EventListDateHeader::first-letter {
    text-transform: uppercase;
}


.EventListDateSection {
    grid-column: 1;
    /* border-radius: 60px;; */
    /* box-shadow:  inset 0 -40px 40px var(--main-bg-color), inset 0 0 1000px var(--main-bg-color), inset 0 0 20px var(--main-bg-color); */

    background-image: 
    /* linear-gradient( 180deg, transparent 94%, var(--main-bg-color) 95%), */
    /* radial-gradient( circle, transparent 30%, var(--main-bg-color) 85%), */
    /* linear-gradient( 0deg, var(--main-bg-color), var(--main-fg-color)) */
    ;

    /* background: var(--main-bg-color); */

}


.EventListFeaturedSection {
    background: transparent;
    box-shadow: none;


    padding-bottom: var(--gap-size);
    /* background: var(--main-bg-color); */
    
}
