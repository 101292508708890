/* Positioning and logic for datetime-display.js */

.DatetimeDisplayContainer {
    grid-row: 1;
    grid-column: 2 / span 7;
}


.DatetimeRelative {

}