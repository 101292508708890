

.EventViewContent {
    /* background: radial-gradient(circle , var(--secondary-bg-color), var(--main-bg-color)); */
    padding-bottom: calc(var(--navbar-height) / 2);
    z-index: 200;
    /* animation: var(--anim-fade-in); */
    animation-delay: .3s;
    max-width: var(--max-width);

}

.EventViewContent > *  {
        animation: var(--anim-fade-in);
        animation-delay: .3s;

    
    }


.EventViewDisclaimer {
    grid-column: 2 / span 10;
    grid-row: 7;
    padding-bottom: calc(var(--navbar-height) );
    animation: var(--anim-fade-in);
    animation-delay: .5s;
}


.EventDetailsTextBlock {
    margin-bottom: var(--row-size);
    grid-row: 4;
    grid-column: 2 / span 10;
    animation: var(--anim-fade-in);
    animation-delay: .4s;
    max-width: 375px;
}

.cancelledEvent {
    grid-row: 1;
    grid-column: 2 / span 10;
    justify-self: left;

}

.cancelledEvent > span {
    font-size: var(--font-size-small);
    text-transform: uppercase;
    /* color: var(--main-bg-color); */
    padding: var(--gutter-size);
    border-radius: 6px;
    border: 1px solid var(--secondary-accent-color); 
    background: var(--main-bg-color); 
    font-weight: 600;
    width: 100%;
  
  }

.BtnContainer {
    grid-row: 3;
    grid-column: 2 / span 10;
    display: grid;
    grid-template-columns: auto;
    grid-auto-columns: min-content;
    grid-template-rows: min-content;
    align-items: center;
    justify-items: right;
    gap: var(--gutter-size);
}

.BtnOpenWeb {
    display: inline-block;
    grid-row: 1;
    grid-column: 2;
    /* grid-row: 3;
    grid-column: 2 / span 3; */
    /* justify-self: left; */

}


.BtnOpenMap {
    display: inline-block;
    grid-row: 1;
    grid-column: 1;
    /* font-weight: 100; */
    /* grid-column: 9 / span 3; */
    /* justify-self: right; */

}

