.FilterViewBar {
    /* transform: translateY(calc(var(--row-size)*-1)); */

    /* display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%; */
    /* bottom: 0;
    left: 0; */
    width: 100%;

    justify-items: center;
}


.FilterViewOption {
    /* margin: 6px; */
    /* padding: 6px; */
    margin-right: var(--gutter-size);
    /* margin-left: 0; */
    /* transform: translateX(-12px); */


    font-family: 'Roboto Condensed', sans-serif;
    color: var(--secondary-font-color);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    text-decoration: none;
    cursor: pointer;

    display: block;

}



.FilterViewListItem a.chosen{
    /* background: red; */
    color: var(--main-accent-color);
    font-weight: 600;
    /* border-top: 1px solid var(--main-accent-color); */
}

.FilterViewOption:hover {
    color: white;
}


