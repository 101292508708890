@keyframes KeyframesVerifiedGlint {
    0% {background-position: -100% -100%; }
    100% {background-position: 100% -100%; }
    /* 0%    {   transform: translateY(0px)    ;}
    20%   {   transform: translateY(0px)    ;}
    25%   {   transform: translateY(-6px)  ;}
    27%   {   transform: translateY(-6px)  ;}
    32%   {   transform: translateY(0px)  ;}
    34%   {   transform: translateY(-1px)  ;}
    36%   {   transform: translateY(-1px)  ;}
    38%   {   transform: translateY(0px)  ;}

    100%  {   transform: translateY(0px)  ;} */
}

.UIVerified {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-columns: 100%;
    align-items: center;
    justify-items: center;
    grid-column: 2;
    padding: 0;
    height: 16px;
    width: 16px;

    /* animation-name: KeyframesVerifiedGlint;
    animation-duration: 3s;
    animation-iteration-count: infinite; */
}

.UIVerifiedCheck {
    font-size: var(--font-size-small);
    color: var(--main-font-color);
    grid-row: 1;
    grid-column: 1;
    z-index: 303;

}

.UIVerifiedShield {
    font-size: var(--font-size-large);
    color: var(--tertiary-accent-color);
    grid-row: 1;
    grid-column: 1;
    z-index: 302;

    animation-name: KeyframesVerifiedGlint;
    animation-timing-function: cubic-bezier(.25,1.08,.33,1);

    animation-duration: 2s;
    animation-iteration-count: infinite;



    background: linear-gradient(-45deg, var(--tertiary-accent-color) 70.2%, var(--main-font-color) 76.7%, var(--main-font-color) 78.7%,  var(--tertiary-accent-color) 86.7% );
    background-clip: unset;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200%;
    text-shadow: var(--product-shadow);



}