.ButtonText,
.button-link {
    font-family: 'Roboto Condensed', sans-serif;
    color: var(--secondary-font-color);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    text-decoration: none;
    cursor: pointer;
  }
  
.ButtonText,
.button-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    text-decoration: none;
    cursor: pointer;
}


.ButtonText {
    color: var(--main-accent-color);
    border-radius: 600px; 
    background: var(--main-fg-color);
    
    cursor: pointer;
    text-decoration: none;

    font-weight: 600;
    display: inline-grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;

}

a.ButtonText:visited {
    color: var(--main-accent-color);
}

.ButtonTextLabelContainer {
    justify-self: center;
    align-self: center;
    grid-row: 1;
    /* grid-column: 1; */
    
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 100%;
    gap: var(--padding-size);
    padding: var(--gutter-size);
    text-align: center;
    justify-content: center;
    
    /* width: 60%; */


    /* white-space: nowrap;
    overflow: hidden;

    background: white; */

}


.ButtonTextIcon {
    display: block;
    /* grid-column: 1; */
    font-size: var(--font-size-large);
    grid-row: 1;
    margin: 0;
    align-self: center;
    justify-self: right;
    max-width: 16px;
    
}

.ButtonTextLabel {
    display: block;
    grid-row: 1;
    /* grid-column: 2; */
    align-self: center;
    text-align: center;
    justify-self: left;
    white-space: nowrap;
    font-family: var(--font-alt);
    padding: 0 var(--gutter-size) 0 var(--gutter-size);
}


.ctaButton {
    border: 1px solid var(--main-accent-color);
    /* color: var(--main-bg-color); */

}


.ButtonText.cta,
.ButtonText.cta:visited {
    
    background: var(--main-accent-color);
    color: var(--main-bg-color);
    border: none;
}

