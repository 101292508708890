.ArticleViewComponent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100%;
    /* animation: var(--anim-slide-right); */
    /* background: var(--main-fg-color); */
}


.ArticleViewComponent > * > * > .content {
    margin-top: calc(var(--row-size) * 3 + var(--gutter-size)) ;
    padding-bottom: calc(var(--navbar-height) / 2);
    overflow: hidden;
    animation: var(--anim-slide-right);
    gap: var(--margin-size);
    grid-template-columns: 1fr repeat(10, 3fr) 1fr;

}



.HomeViewTopicHeader{
    grid-column: 2 / span 10;
}

.EventListDateHeader {
    grid-column: 2 / span 10;
    margin-top: var(--row-size);
    margin-bottom: var(--margin-size);

}

.ArticleTopImg,
.ArticleTopVideo {
    grid-column: 1 / span 12;
    max-width: 100%;
    max-height: 300px;
    object-fit: scale-down;
    justify-self: center;
}


.ArticleViewBody {
    grid-column: 2 / span 10;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    gap: var(--gutter-size);
}

.ArticleViewBody > h1 {
    font-size: var(--font-size-xlarge);
    line-height: var(--font-size-xlarge);
    font-weight: 400;
}

.ArticleMarkdown > * > p > img {
    text-align: center;
    display: block;
    max-width: 100%;
    margin: var(--margin-size) 0 var(--margin-size) 0;
    /* width: 100%; */

}

.ArticleMarkdown > * > p {
    margin-top: var(--gutter-size);

}
.ArticleInfoBar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr 1fr;
}

.ArticleTimestamp {
    color: var(--secondary-font-color);
}

.ArticleSocial {
    justify-self: right;
    font-size: var(--font-size-large);
}

.ArticleSocial > i {
    padding-left: 16px;
}

.ArticleActions {
    width: 100%;
    justify-self: right;
    display: grid;
    grid-template-columns: 100%;
    margin-top: var(--margin-size);
}

.ArticleActions > .ButtonText {
    display: block;
    justify-self: right;
    text-align: right;
    min-width: 33%;
}