.button-install-app { 
    background: var(--main-accent-color)0;
    color: var(--main-bg-color);



    border-radius: 600px; 
    /* background: var(--main-fg-color);
    box-shadow: var(--button-shadow); */
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;
    grid-column: 2 / span 10;

    margin-top: var(--gutter-size);
    grid-row: 8;
    width: 100%;
    /* line-height: var(--font-size-large); */
}