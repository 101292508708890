
.blurbackground {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: red; */
    background: var(--main-bg-color);
    opacity: 1;
    z-index: 999;
    height: 100vh;
    width: 100%;
    
}

.welcomeimage {
    width: 100%;
    align-self: center;
    justify-self: center;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-content: center;
    margin-bottom: var(--margin-size);
    margin-top: var(--margin-size);

}
.welcomeimage > img {
    justify-self: center;
    max-width: 40%;
}

.blockbackground {
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%;
    height: 100%; */
    background: var(--main-bg-color);
    z-index: 1000;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;

    animation: var(--anim-fade-in);
    animation-duration: .1s;

    height: 100vh;
    width: 100%;

    overflow-y: scroll;

}

.LegalManagerComponent {

    grid-column: 2 / span 10;
    /* background: var(--main-bg-color); */
    padding-top: calc( 96px + 10% );
    /* padding-bottom: var(--navbar-height); */
    /* var(--margin-size); */
    overflow-y: scroll;

    max-width: var(--max-width);
    min-height: 375px;
    height: 100%;
    /* min-width: var(--min-width); */
    justify-self: center;
    align-self: flex-end;

    animation: var(--anim-slide-up);

}

@media only screen and (min-width: 768px) {
    .LegalManagerComponent {
        align-self: flex-start;
    }
}


.LegalManagerComponent > h1 {
    margin-bottom: var(--margin-size);
    margin-top: var(--margin-size);

}

.LegalManagerComponent > .options {
    padding: 0;
    padding-left: var(--margin-size);
    padding-right: var(--margin-size);
    min-height: min-content;
}


.LegalManagerComponent > .options > li {
    list-style: none;
    font-size: var(--font-size-xmedium);
    line-height: var(--font-size-xlarge);
    font-family: var(--font-alt);
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1;
}

.legalLinks > li > a,
.legalLinks > li > a:visited {
    color: var(--main-accent-color);
}

.LegalManagerComponent > .buttons-container {
    /* position: fixed;
    bottom: var(--navbar-height);
    right: var(--margin-size); */
    margin-top: var(--margin-size);
    margin-bottom: calc(var(--row-size)*2);
    width: 100%;
    text-align: right;

}


.LegalManagerComponent > .buttons-container > .ButtonText:first-child {
    margin-right: var(--gutter-size);
    /* height: var(--row-size); */
}

.blockbackground::-webkit-scrollbar,
.LegalManagerComponent::-webkit-scrollbar {
    display: none;
  }