.TileListItemContainer {
    z-index: 1;
    /* background: var(--main-fg-color); */
    /* margin-bottom: 28px; */
    padding-top: 14px;
    padding-bottom: 14px;
    grid-column: 1 / span 12;
    margin-bottom: 2px;

    /* interaction stuff */
    cursor: default;

    /* animation stuff */
}
  
    

.TileListItemContent {
    grid-column: 2 / span 10;
    display: grid;
    grid-template-rows: 100% ;
    grid-template-columns: 100%;
    min-height: 112px;

}



.TileListItemButton {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    justify-self: center;
}