
.LoadingDisplayContainer {
    --stroke-array: 100px;
    --stroke-offset: 100px;
}


@keyframes LoadingDisplayAnimation {

    0%  {   stroke-dashoffset: calc(var(--stroke-offset) * 2);
            stroke-width: .25px;        }
    50%  {  stroke-dashoffset: var(--stroke-offset); 
            stroke-width: .5px;        }
    100% {  stroke-dashoffset: 0; 
            stroke-width: .25px;        } 
}


.LoadingDisplayContainer {

    /* width: 100%; */
    min-height: 340px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-items: center;
    justify-items: center;


}

.LoadingDisplayText {


    /* height: 100%; */
    margin: auto auto;
    align-self: center;
    justify-self: center;
    text-align: center;

    height: var(--row-size);
    line-height: var(--row-size);

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30% var(--row-size);
    animation: var(--anim-fade-in);


}

.LoadingDisplayString {
    grid-row: 3;
    grid-column: 1/span 2;
    align-self: center;
    justify-self: center;
    font-family: var(--font-alt);
    font-size: var(--font-size-medium);
    text-transform: uppercase;
    color: var(--secondary-font-color);
    margin-top: 6px;
    /* border-top: 1px solid var(--secondary-font-color); */


}

.LoadingImage {


    width: 128px;
    height:128px;

    display: grid;
    align-self: center;
    justify-self: center;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    border-radius: 48px;;




   
}

.LoadingImage svg {
    align-self: center;
    justify-self: center;
    transform: translateY();
    width: 96px;
    height: 96px;

    
}

.LoadingImage svg path {

    fill: var(--main-bg-color);
    paint-order: stroke;
    stroke: var(--main-accent-color);
    stroke-dasharray: var(--stroke-array);
    stroke-dashoffset: var(--stroke-offset);

    stroke-width: .25px;
    
    animation-name: LoadingDisplayAnimation;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: forwards;



}

.LoadingImage {
    /* color: red; */
    width: 100%;
 
    

}