
.logo-softops svg {
    align-self: center;
    justify-self: center;
    transform: translateY(-32px);
    width: 128px;
    height: 96px;

}

.logo-softops svg path {
    fill: var(--main-accent-color);

}