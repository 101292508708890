.HorizontalDisplayContainer {
    --horizontal-card-width: 135px;
    /* --main-bg-color: white;
    --secondary-bg-color: grey; */


    grid-row: 6;
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
    /* min-height: 375px; */
    /* max-height: 200px; */
    align-self: flex-start;
    width: auto;
    padding-bottom: var(--margin-size);
    padding-top: var(--margin-size);
    
    background: var(--main-bg-color);

}

.HorizontalDisplayContainer > .TopicHeaderContainer  {
    /* grid-row: 4; */
    grid-column: 2 / span 10;
    justify-self: left;
}

.HorizontalDisplayCards {
    display: grid;
    grid-column: 1 / span 12;
    grid-auto-flow: column;
    gap:  var(--gutter-size);
    grid-template-rows: 1 / auto;
    grid-auto-columns: min-content;
    overflow-x: scroll;
    /* overflow-y: hidden; */

    margin: 0;
    margin-top: var(--margin-size);
    padding-top: var(--margin-size);
    padding-bottom:  calc(var(--row-size) *2);
    padding-left: var(--margin-size);
    /* background: red; */
    width: calc(100%);
    

}


.HorizontalDisplayCards::-webkit-scrollbar {
    display: none;
  }

/* .PackageDisplayCardsHorizontal:before, */
.HorizontalDisplayCards:after {
  content: '';
  width: var(--margin-size);
}

.HorizontalItemContainer {
    display: grid;
    grid-template-rows: 60% 20% 20%;
    align-items: center;
    justify-items: center;
    text-align: center;
    /* background: var(--secondary-bg-color); */
    background-image: 
    linear-gradient( 180deg, transparent 60%, var(--secondary-bg-color) 62%),
    radial-gradient( circle, transparent 20%, var(--secondary-bg-color) 45%),
    linear-gradient( 180deg, var(--main-bg-color) 35%, var(--secondary-fg-color) 100%)
    ;
    /* background: linear-gradient(var(--secondary-bg-color) 0%, var(--main-bg-color) 100%); */
    border-radius: 20px;

    width: var(--horizontal-card-width);
    padding:  var(--gutter-size);

    
    box-shadow: var(--button-shadow);

    /* perspective: 16px;
    perspective-origin: 50%; */
}

.product-shadow {
    background: var(--fake-shadow);
    width: 64px;
    height: 32px;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    align-self: flex-end;
    /* transform: translateY(var(--gutter-size)); */
    filter: blur(10px);
    z-index: 9;
    border-radius: 200px;
    transform: translateY(-10px);
}

.HorizontalItemImage {
    /* box-shadow: var(--product-shadow) ; */
    align-self: flex-end;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    z-index: 10;
    transform: translateY(-20px);
    /* width: 150px; */
    /* transform-origin: 50%;
    transform: translateZ(15px) translateY(47px) scale(.13); */
    
}

.HorizontalPackageItemValue {
    font-weight: 400;


    /* transform-origin: 50%;
    transform: translateZ(2px) translateY(-5px) scale(.75); */
}

.PackageItemName {
    align-self: flex-start;
    /* transform-origin: 50%;
    transform: translateZ(0) ; */

}

.PackageBookNow {
    align-self: flex-end;
    justify-self: right;
    text-align: right;
}