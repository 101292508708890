
@keyframes KeyframesTopicHeaderHeading {
    from    { transform: translateY(-300%); color: var(--main-bg-color); }
    to      { transform: translateY(0);}
}

@keyframes KeyframesTopicHeaderContainer {
    from    { width: 10%;
              height: 0px;
              border-bottom: var(--row-size) solid var(--main-bg-color);}
    to      { width: 100%;
              }
}


.TopicHeaderContainer {

    border-bottom: 1px solid var(--secondary-font-color);
    padding-bottom: 3px;
    margin-bottom: 3px;
    min-height: var(--row-size);
    overflow: hidden;

    /* animation-name: KeyframesTopicHeaderContainer; */
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: .1s;

    z-index: 101;
    
}

.TopicHeaderHeading,
.TopicHeaderSubheading {
    animation-name: KeyframesTopicHeaderHeading;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: .1s;
    letter-spacing: 0.03em;
}

.TopicHeaderSubheading {
    margin-top: var(--gutter-size);
}

