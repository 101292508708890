@keyframes KeyframesEventViewBar {
    from    { color: var(--main-accent-color); }
    to      { ;}
}

@keyframes KeyframesEventViewImageBG0 {
    0% { transform: translateZ(-2px) translateY(0) translateY(20%) translateX(100%) scale(3); }
    100%   { transform: translateZ(-2px) translateY(0) translateY(10%) translateX(100%) scale(3);}
}

@keyframes KeyframesEventViewImageBG1 {
    0% { transform: translateZ(-6px) translateY(90%) translateX(300%)   scale(7);  }
    100%   { transform: translateZ(-6px) translateY(100%) translateX(300%)   scale(7);}
}

@keyframes KeyframesEventViewImageTop {
    0% { transform: translateZ(0px) translateY(-285%) translateX(calc(100px + 10vw))   scale(1); }
    100%   { transform: translateZ(0px) translateY(-280%) translateX(calc(100px + 10vw))   scale(1); }
}



/* @media only screen and (max-width: 768px) {
    .EventViewHeaderBackground-1 {
        height: 100%;
        width: auto;
    }
} */

.EventViewHeaderContainer {

    /* Main container fro the header */
    position: relative;
    top: 0;
    left: 0;
    min-height: var(--header-height-large);
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;

    background: var(--main-bg-color);
    overflow: hidden;
    align-items: center;
    justify-items: center;
 
 
    justify-content: center;

    /* Parallax */
    /* overflow-x: hidden;
    overflow-y: auto;
    perspective-origin: 0 0;
    perspective: 1px; */



}


.EventViewHeaderContent{
    align-self: flex-start;
    display: grid;
    /* Contains the actual content of the header */
    max-width: var(--max-width);
    min-width: var(--min-width);
    overflow-x: hidden;
    width: 100%;
    grid-template-columns: 1fr repeat(10, 2fr) 1fr;
    grid-auto-rows: minmax(var(--row-size), auto);
    grid-gap: 6px;

    grid-column: 1;
    grid-row: 1;
    z-index: 501;
    animation: var(--anim-slide-down);

    -moz-box-shadow:    inset 0 3px 0 var(--main-accent-color);
    -webkit-box-shadow: inset 0 3px 0 var(--main-accent-color);
    box-shadow:         inset 0 3px 0 var(--main-accent-color);
}


.EventViewButtonBack {
    grid-row: 2;
    grid-column: 2 / span 2;
    align-self: flex-end;
    justify-self: left;
    transform: translateX(calc(var(--margin-size) * -1));
    animation: var(--anim-fade-in);
    animation-delay: .5s;
}

.EventViewButtonBack.promoted {
    background: var(--tertiary-accent-color);
    color: var(--main-font-color);
}

.EventViewTitle {
    display: block;
    height: auto;
    grid-row: 5;
    grid-column: 2 / span 10;
    font-size: 2.8em;
    line-height: 36px;
    text-align: left;
    font-weight: 300;

    border-bottom: 1px solid var(--secondary-font-color);
    padding-bottom: var(--line-height-normal);
    /* margin-bottom: 6px; */

    z-index: 311;
    text-shadow: var(--text-shadow-large);

}


.EventViewSecondaryDetails {
    grid-row: 6;
    grid-column: 2 / span 10;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1;
    animation: var(--anim-slide-down);
    animation-delay: .5s;

}

.EventViewSeriesName {
    grid-row: 1;
    /* grid-column: 2 / span 10; */
    color: var(--secondary-font-color);
    line-height: var(--margin-size);
    
}


.EventViewHeaderTags{
    /* grid-column: 2 / span 3; */
    grid-row: 2;
    z-index: 311;
    text-shadow: var(--text-shadow-small);
}

.EventViewBar {
    grid-row: 4;
    transform: translateY(6px);
    grid-column: 2 / span 10;
    align-self: flex-end;
    margin-top: 6px;
    height: var(--row-size);

    display: grid;
    grid-template-columns: 1fr repeat(10, 2fr) 1fr;
    grid-gap: 6px;
    align-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    /* background: var(--main-fg-color); */
    z-index: 310
}

.EventViewBarOperator {
    display: grid;
    grid-column: 1;
    grid-template-columns: auto auto;
    width: min-content;
    /* overflow: hidden; */

    animation-name: KeyframesEventViewBar;
    animation-duration: .6s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: .1s;
    


}

.EventViewBarOperatorName{
    white-space: nowrap;
}

.EventViewBarVerified {
    grid-column: 2;
}

#event-list-partition {
    display: grid;

}