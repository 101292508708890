@keyframes slowBlink { 
    from    {   opacity:    0;  }
    to      {   opacity:    1;  }  
}


.lazy-load{

    font-family: var(--font-alt);
    text-transform: uppercase;
    color: var(--font-color-secondary);

    align-self: center;
    justify-self: center;
    text-align: center;

    animation: slowBlink 1s ease-in-out alternate-reverse infinite ;
}


.static {
    animation: slowBlink 2s ease-in-out backwards 1 ;
    

}