
.parallaxContainer {
    --header-bg-max-width: var(--max-app-width);
    --header-hero-nax-height: 420px;

    grid-column: 1;
    grid-row: 1;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    align-self: flex-start;
    justify-items: center;
    align-items: center;
    text-align: center;

    height: var(--header-height-large);
    width: 100%;
    max-width: var(--header-bg-max-width);
    min-width: var(--min-width);


    margin: 0;
    padding: 0;
    border: 0;
    /* background: red; */

      
    overflow-x: hidden;
    overflow-y: hidden;



}


.EventViewHeaderBackground-block {
    display: none;
    top: 0;


    height: 100%;
    width: 100%;
    max-width: var(--header-bg-max-width);
    min-width: var(--min-width);
    z-index: 1;


    background: var(--secondary-fg-color);
    /* background: radial-gradient(    63.86% 82.14% at 50.13% 15.48%,
                                    rgba(25, 39, 42, 0) 0%, 
                                    rgba(25, 39, 42, 0.879699) 54.17%,
                                     var(--main-bg-color) 69.27%); */

    /* Animation */
    /* animation-name: KeyframesEventViewImageBG0; */
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-delay: .2s;
    animation-fill-mode: backwards;
    /* animation-direction: alternate-reverse; */
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

}


.EventViewHeaderBackground-0 {

    
    grid-column: 1;
    grid-row: 1;
    /* top: 0; */
    /* transform: translateZ(-2px) translateY(0) translateY(10%) translateX(100%) scale(3); */
    /* max-height: var(--header-height-large); */
    max-width: var(--header-bg-max-width);
    opacity: 1;

    z-index: 2;
    
    transform: translateY(0);
    animation: SlideBackgroundLoad 1s .6s ease-in-out backwards;

    text-align: center;
}

.EventViewHeaderBackground-0 > path {
    max-width: 100px;
}



.EventViewHeaderBackground-1 {
    grid-column: 1;
    grid-row: 1;
    /* top: 0;
    left: 0; */
    /* transform: translateZ(-6px) translateY(100%) translateX(300%)   scale(7); */
    /* max-height: var(--header-height-large); */
    max-width: var(--header-bg-max-width);
    opacity: 1;

    z-index: 3;
    /* align-self: flex-start;
    justify-self: center; */

    transform: translateY(0);
    animation: SlideBackgroundLoad 1s .4s ease-in-out backwards;
}



.noImage{
    background: var(--main-fg-color);
}

.EventViewHeaderBackground-2 {
    grid-column: 1;
    grid-row: 1;
    position: relative;
    width: auto;
    top: 5vh;
    right: -10vw;

    max-height: var(--header-hero-nax-height);
    
    opacity: 1;
    z-index: 302;
    transform: translateY(0);
    animation: SlideBackgroundLoad 1s .2s ease-in-out backwards;
}

@keyframes SlideBackgroundLoad {
    from { transform: translateY(20px );}
}


@keyframes BackgroundLoad {


    0%  {   stroke-dashoffset: calc(var(--stroke-offset) );
            stroke-dasharray: calc(var(--stroke-array) );
            stroke-width: 4px;        }
    100% {  stroke-dashoffset: var(--offset-finish);
            stroke-dasharray: var(--array-finish);
            stroke-width: 0px;        } 
}


@keyframes FillBackgroundLoad {
    from { fill: var(--main-bg-color) }
}

@keyframes FillBackgroundLoad-b {
    from { background-position-y: -200px;}
}



#svg-fg-light {
    fill: var(--main-bg-color);
    animation: FillBackgroundLoad 1s 1s ease-in backwards;
    /* transition: all FillBackgroundLoad 1s linear */
    
}

#svg-fg-shadow {
    fill: var(--main-bg-color);
    animation: FillBackgroundLoad 1s 1s ease-in backwards;
    /* fill-opacity: .2; */
}

#svg-bg-top {
    --stroke-offset: -4000px;
    --stroke-array: 4000px;
    --offset-finish: calc(var(--stroke-offset) * 1.7);
    --array-finish: calc(var(--stroke-array) * 1.7);

    fill: var(--main-bg-color);
    paint-order: stroke;
    stroke: var(--main-font-color);
    stroke-dashoffset: var(--offset-finish);
    stroke-dasharray: var(--array-finish);

    stroke-width: 0px;
    
    animation: FillBackgroundLoad 1s .5s ease-in backwards;
}

#svg-bg-mid {
    fill: var(--main-fg-color);
    animation: FillBackgroundLoad 1s .4s ease-in backwards;
}


#bg-container {

    background: linear-gradient(var(--secondary-fg-color) 0%, var(--main-bg-color) 40%), var(--main-bg-color);
    background-position-y: 0%;
    /* background-size: 500%; */
    background-repeat: no-repeat;
    animation: FillBackgroundLoad-b .5s .2s ease-in-out backwards;



    
;

    /* background: red; */
}


.parallaxContainer.promoted#bg-container {
    background-image:
    linear-gradient(81deg,transparent 86.3%,var(--tertiary-accent-color) 86.3%,var(--tertiary-accent-color)),
    linear-gradient(72deg,transparent 75.4%,var(--main-font-color) 75.4%,var(--main-font-color)),
    linear-gradient(63deg,transparent 66.25%,var(--tertiary-accent-color) 66.25%,var(--tertiary-accent-color)),
    linear-gradient(54deg,transparent 57.85%,var(--main-font-color) 57.85%,var(--main-font-color)),
    linear-gradient(45deg,transparent 49.9%,var(--tertiary-accent-color) 49.9%,var(--tertiary-accent-color)),
    linear-gradient(36deg,transparent 41.96%,var(--main-font-color) 41.96%,var(--main-font-color)),
    linear-gradient(27deg,transparent 33.6%,var(--tertiary-accent-color) 33.6%,var(--tertiary-accent-color)),
    linear-gradient(18deg,transparent 24.35%,var(--main-font-color) 24.35%,var(--main-font-color)),
    linear-gradient(9deg,transparent 13.6%,var(--tertiary-accent-color) 13.6%,var(--tertiary-accent-color))
}


.parallaxContainer.promoted > * >  #svg-bg-mid  {
    fill: var(--tertiary-accent-color);
}


/* @media only screen and (max-width: 768px) {
    .EventViewHeaderBackground-2 {
        right: 30vw;
    }
} */