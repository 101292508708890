.ViewHeaderContainer {
    /* box-shadow: 0px 15px var(--row-size) var(--main-bg-color); */
    z-index: 301;


    position: fixed;
    top: 0;
    left: 0;
    background: var(--main-bg-color);
    width: 100%;
    height: calc(var(--row-size) * 2 + var(--gutter-size));
    display: grid;

    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    overflow: hidden;

    /* box-shadow: 0px 45px 40px var(--main-fg-color); */
 

}


  .ViewHeaderContainer h1 {
    grid-row: 1 / span 1;  
    grid-column: 2 / span 10;
    /* font-size: 16px;
    line-height: 16px; */
    line-height: 0;
    padding:0;
    margin: 0;
    align-self: flex-end;
    justify-self: left;
  }

  .ViewHeaderContainer  > .content > .FilterViewBar {
    grid-row: 2 / span 1;
    align-self: center;

    /* grid-column: 2 / span 10;
    align-self: left; */
  }


  .ViewHeaderContainer > .content {
    max-width: var(--max-app-width);
    min-width: var(--min-width);
    width: 100%;
    /* height: var(--header-height); */


    display: grid;
    grid-template-rows: repeat(1, var(--row-size));
    grid-template-columns:  1fr repeat(10, 2fr) 1fr;
    grid-gap: 6px;


    -moz-box-shadow:    inset 0 3px 0 var(--main-accent-color);
    -webkit-box-shadow: inset 0 3px 0 var(--main-accent-color);
    box-shadow:         inset 0 3px 0 var(--main-accent-color);
}

.ViewHeaderContainer > .content > .ViewHeaderLinksBar {
    grid-row: 2 / span 1;
    grid-column: 2 / span 10;
    align-self: left;
}

.ViewHeaderContainer > .content > .settings {
    grid-row: 1;
    grid-column: 11;
    align-self: flex-end;
    justify-self: flex-end;
    color: var(--secondary-font-color);
    font-size: var(--font-size-large);
    line-height: 0;
}