@keyframes KeyframesSystemNotePop {
    from    { 
            transform: translatex(100%);
            background-color: white; 
            color: var(--main-bg-color);}

    to      { transform: translatex(0%);
}
}

@keyframes clickedWaiting {
    from    { opacity: 1;}
    to      { opacity: .3;}
}



.SystemNotificationsContainer {
    position: fixed;
    bottom: calc(var(--navbar-height) + var(--margin-size) );
    left: 0;
    width: 100%;
    z-index: 501;

    display: grid;
    grid-template-columns: 100%;
    
    align-items: center;
    justify-items: center;
    /* background: var(--secondary-accent-color); */
}

.SystemNotification {
    max-width: var(--max-width);
    min-width: var(--min-width);
    width: 100%;

    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(12, 1fr);

    text-align: left;
    color: var(--main-font-color);


    animation-name: KeyframesSystemNotePop;
    animation-duration: .3s;
    animation-fill-mode: backwards;
    /* overflow: hidden; */
    


}

.SystemNotificationContent {
    grid-column: 2/span 10;
    padding: 16px;

    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 7fr 3fr;

    align-items: center;
    justify-items: center;

    background: var(--secondary-accent-color);
    border-radius: 6px;
    box-shadow: var(--button-shadow);
    
}


button > .SystemNotificationContent {
    font-weight: medium;
}

.SystemNotificationIcon {
    font-size: var(--font-size-xlarge);
    
}

.SystemNotificationMessage {

    /* padding-left: 16px; */
    font-family: var(--font-normal);
    /* text-transform: uppercase; */
    text-align: left;
    justify-self: left;


    font-size: var(--font-size-xmedium);

    /* line-height: calc(var(--row-size)*2); */

}

.clickedWaiting > * > * {
    animation: clickedWaiting .6s ease-in-out forwards 1;
}

.clickedWaiting > .SystemNotificationContent {
    background: var(--main-fg-color);
}


.SystemNotificationAction {
    /* padding-left: 16px; */
    font-family: var(--font-alt);
    text-transform: uppercase;
    text-align: center;
    justify-self: center;


    font-size: var(--font-size-xmedium);
    font-weight: medium;
    

}