.ViewBarContainer {
    display: grid;
    grid-template-columns: 100%;
    align-content: center;
    align-items: center;
    padding: 0;
    margin: 0;

    /* margin-left: var(--gutter-size); */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.ViewBarButtonContainer {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
    max-width: var(--max-app-width);
    /* min-width: var(--min-width); */
    justify-self: left;
    z-index: 302;

    align-items: center;
    align-content: center;

  }

  .ViewBarButtonContainer ul {
      width: 100%;
      grid-column: 1 / span 10;
      padding: 0; margin: 0;
      text-align: left;
      /* line-height: 18px; */
      /* height: 12px; */

      display: grid;
      grid-template-rows: 1fr;
      grid-auto-columns: min-content;

  }

  .ViewBarButtonContainer ul li {
      display: inline-block;
      padding: 0; margin: 0;
      line-height: var(--row-size);
      grid-row: 1;
      

      
  }