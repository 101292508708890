/* Positioning and logic for address-display.js */

.AddressDisplayContainer {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 6px;
    /* Position */
    grid-row: 2;
    grid-column: 2 / span 10;
    margin-top: 21px;
    /* margin-bottom: var(--margin-size); */

    /* Dev */
    /* background: red; */
}

.AddressLocationName {
    grid-row: 1;
    grid-column: 1 / span 10;
}

.AddressStreetDetails {
    grid-row: 2 / span 2;
    grid-column: 1 / span 6;
}

.AddressFeatures {
    grid-row: 4 ;
    grid-column: 1 / span 6;
}

.AddressOpenMap  {
    grid-row: 4 ;
    grid-column: 7 / span 4;
    justify-self: right;

    height: var(--row-size);
    transform: translateX(var(--gutter-size));

}