.OperatorLogoContainer {
    --icon-size: 36px;


    min-width: var(--icon-size);
    max-width: var(--icon-size);
    min-height: var(--icon-size);
    max-height: var(--icon-size)px;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    grid-row: 1;
    grid-column: 1;
    align-self: center;
    justify-self: center;
    text-align: center;

    border-radius: var(--icon-size);
    /* background: red; */

    /* box-shadow: inset var(--shadow-blend-bg2); */
}


.OperatorLogoContent{
    grid-row: 1;
    grid-column: 1;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    min-width: var(--icon-size);
    max-width: var(--icon-size);
    min-height: var(--icon-size);
    max-height: var(--icon-size);

    margin: auto;
    padding: auto;

    align-self: center;
    justify-self: center;
    text-align: center;

    overflow: hidden;

}

.OperatorLogoIcon{
    display: block;

    grid-row: 1;
    grid-column: 1;
    width: 100%;
    max-width: var(--icon-size);

    line-height: 100%;

    text-align: center;
    align-self: center;
    justify-self: center;


}
.OperatorLogoIcon.text{
    font-family: var(--font-alt);
    font-size: 22px;
    font-weight: 100;
    color: var(--secondary-fg-color);
    letter-spacing: -0.1em;
    text-transform: uppercase;
    text-align: center;

}


.OperatorLogoIcon.image{

}

.iconPromoted > * > .OperatorLogoIcon.text {
    /* -webkit-text-fill-color: transparent; */
    /* text-shadow: var(--product-shadow); */
    font-weight: 400;
    color: var(--main-accent-color);
}

  
.OperatorLogoVerified {
    position: relative;
    grid-row: 1;
    grid-column: 1;
    justify-self: right;
    align-self: flex-end;
    /* margin-bottom: 6px; */
    left: 5px;
    /* bottom: -16px; */
    z-index: 101;

    text-shadow: var(--text-shadow-small);
  
  }


.iconPromoted {
    /* background: var(--main-accent-color);
    background-clip: unset;
    -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */


    /* animation-name: promoted;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    
    background-size: 200%; */

}

@keyframes promoted {
    0% {background-position: -100% -100%;}
    100% {background-position: 100% -100%;}
}



.previewRow.promoted {
    /* background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%); */
    /* background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%); */
    /* height: var(--row-size);
    line-height: var(--row-size); */
    background-image: radial-gradient( circle 859px at 7.5% 18%,  rgba(234,6,185,1) 4.2%, rgba(251,179,8,1) 92.7% );
    
    border-radius: 6px;     
}


