
.blurbackground {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: red; */
    background: var(--main-bg-color);
    opacity: 1;
    z-index: 999;
    height: 100vh;
    width: 100%;
    
}


.blockbackground {
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%;
    height: 100%; */
    background: var(--main-bg-color);
    z-index: 1000;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;

    animation: var(--anim-fade-in);
    animation-duration: .1s;

    height: 100vh;
    width: 100%;

}

.PreferencesManagerComponent {

    grid-column: 2 / span 10;
    /* background: var(--main-bg-color); */
    padding-top: calc( 96px + 10% );
    /* padding-bottom: var(--navbar-height); */
    /* var(--margin-size); */
    overflow-y: scroll;

    max-width: var(--max-width);
    min-height: 375px;
    height: 100%;
    /* min-width: var(--min-width); */
    justify-self: center;
    align-self: flex-end;

    animation: var(--anim-slide-up);

}

@media only screen and (min-width: 768px) {
    .PreferencesManagerComponent {
        align-self: flex-start;
    }
}


.PreferencesManagerComponent > h1 {
    margin-bottom: var(--margin-size);
    margin-top: var(--margin-size);

}

.PreferencesManagerComponent > .options {
    padding: 0;
    padding-left: var(--margin-size);
    padding-right: var(--margin-size);
    min-height: min-content;
}


.PreferencesManagerComponent > .options > li {
    list-style: none;
    font-size: var(--font-size-xmedium);
    line-height: var(--font-size-xlarge);
    font-family: var(--font-alt);
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1;
}

.option-toggle {
    opacity: 0;
    grid-column: 1;
    margin: 0;
    display: inline-block;
    
}

.option-toggle:checked {

}

.option-toggle:checked ~ * {
    color: var(--main-accent-color);

}

.toggle-icon {
    font-size: var(--font-size-large);
    line-height: var(--font-size-large);
    grid-column: 2;
    align-self: center;
    justify-self: right;
    
}

.option-toggle ~ label {
    display: inline-block;
    grid-column: 1;
    align-self: center;
    justify-self: left;
    width: 100%;

    display: grid;
    grid-template-columns: auto var(--row-size);
    grid-template-rows: 1;
}

.buttons-container {
    /* position: fixed;
    bottom: var(--navbar-height);
    right: var(--margin-size); */
    margin-top: var(--margin-size);
    margin-bottom: var(--margin-size);
    width: 100%;
    text-align: right;

}


.buttons-container > .ButtonText:first-child {
    margin-right: var(--gutter-size);
    /* height: var(--row-size); */
}