@keyframes KeyframesNavbarLoad {
  from {
    transform: translateY(200px);
    color: var(--main-bg-color);
  }
  to {
    transform: translateY(0px)
  }
}


/* @media only screen and (min-width: 768px) {
    .Navbar {
        top: 0;
        bottom: unset;
      }

      .NavbarLinksContainer {
      width: 120px;
      justify-items: flex-end;
      justify-content: flex-end;
      background: transparent;
      box-shadow: 0;
    }

      .NavbarLink {
        background: transparent;
        box-shadow: unset;
      }

} */



.Navbar {
    position: fixed;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    bottom: var(--margin-size);
    left: 0;
    width: 100%;
    height: var(--navbar-height);
    justify-items: center;
    line-height: var(--navbar-height);
  
    z-index: 700;
    animation-name: KeyframesNavbarLoad;
    animation-iteration-count: 1;
    animation-duration: .5s;
    /* overflow: hidden; */

  }

  /* @media only screen and (max-width: 768px) { */
    .Navbar.hide {
      transform: translateY(200px);
      transition: all ease-in-out 1s;
    }
    
    .Navbar.show {
      transform: translateY(0px);
      transition: all ease-in-out .2s;
    }
/* } */




  .NavbarLinksContainer {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(calc(var(--row-size)), auto);
    grid-auto-columns: minmax(calc(var(--row-size)), auto); 
    column-gap: var(--margin-size);
    
    grid-template-rows: 100%;
    padding: 0;
    list-style-type: none;
    margin: auto auto;
    height: var(--navbar-height);
    /* transform: translateY(-20px); */

    background: var(--main-bg-color);
    border-radius: var(--navbar-height);
    box-shadow: var(--product-shadow);
    width: auto;
    transition: width 1s linear;
  }
  
  

  .NavbarLink {


    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    
    margin: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;

    height: 64px;
    width: 64px;
    border-radius: 64px;;



  }

  .soft-cta {
    background: var(--main-accent-color);
    

  }

  .soft-cta > * {
    color: var(--main-bg-color)
  }

  .NavbarLink a:hover,
  .NavbarLink .backLink:hover {
    color: var(--main-font-color);
  
  }

  .NavbarLink a.chosen {
    color: var(--main-accent-color);
    font-weight: 800;
    /* font-weight: 700; */
    /* background: var(--main-bg-color); */
    /* border-bottom: 2px solid var(--main-accent-color); */
  }

  .NavbarLink a,
  .NavbarLink .backLink {
    /* height: var(--navbar-height); */
    display: grid;
    grid-template-rows: 60% 40%;
    align-items: center;
    justify-items: center;
    min-height: var(--navbar-height);
    min-width: calc(var(--min-width)/3);
    /* margin: auto auto;
    padding: auto auto; */
    font-weight: 300;
    transition: all .3s linear;

  }



  .NavbarLinkIcon {
    display: block;
    grid-row: 1;
    font-weight: light;
    font-size: var(--font-size-xlarge);
    line-height: var(--font-size-xxlarge);
    align-self: flex-end;


    animation-fill-mode: backwards;
    animation-name: KeyframesNavbarLoad;
    animation-iteration-count: 1;
    animation-delay: .5s;
    animation-duration: .3s;
    overflow: hidden;
  }

  .NavbarLink .backLink > .NavbarLinkIcon,
  .NavbarLink .backLink > .NavbarLinkText  {
    animation: var(--anim-fade-in);
  }



  .NavbarLinkText {
    display: block;
    grid-row: 2;
    align-self: baseline;

    animation-fill-mode: backwards;
    animation-name: KeyframesNavbarLoad;
    animation-iteration-count: 1;
    animation-delay: .5s;
    animation-duration: .5s;
    overflow: hidden;

 
 
  }

  .nonav {
    display: none
  }