.ViewHeaderLinksBar {
    /* transform: translateY(calc(var(--row-size)*-1)); */

    /* display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%; */
    /* bottom: 0;
    left: 0; */
    width: 100%;

    justify-items: center;


}



.ViewHeaderLinksOption {
    /* margin: 6px; */
    /* padding: 6px; */
    margin-right: var(--gutter-size);
    /* margin-left: 0; */
    /* transform: translateX(-12px); */


    font-family: 'Roboto Condensed', sans-serif;
    color: var(--secondary-font-color);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    text-decoration: none;
    cursor: pointer;

    display: grid;
    grid-template-rows: 100%;
    align-items: center;
    align-content: center;

    overflow-y: hidden;



}

.ViewHeaderLinksOption > i {
    margin-right: 4px;
    /* transform: translateY(5px); */
    /* grid-column: 1; */
    grid-row: 1;
    align-self: center;
    font-size: 24px;

}

.ViewHeaderLinksOption > span {
    /* grid-column: 1; */
    grid-row: 1;
    align-self: center;


}

.ViewHeaderLinks-item {
    height: 19px;
    display: grid;
    align-content: center;
    align-items: center;

}

.ViewHeaderLinks-item a.chosen {
    /* background: red; */
    color: var(--main-accent-color);
    font-weight: 600;
    /* border-top: 1px solid var(--main-accent-color); */
}

.ViewHeaderLinksOption:hover {
    color: white;
}


.view-options {
    grid-row: 4;
    grid-column: 1 / span 12;
    align-self: flex-end;
    margin-top: 6px;
    height: var(--row-size);
  }
  
