.HomeViewComponent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100%;
    /* animation: var(--anim-slide-right); */
    /* background: var(--main-fg-color); */
}


.HomeViewComponent > * > * > .content {
    margin-top: calc(var(--row-size) * 3 + var(--gutter-size)) ;
    padding-bottom: calc(var(--navbar-height) / 2);
    overflow: hidden;
    animation: var(--anim-slide-right);
    gap: var(--margin-size);

}

.HomeViewTopicHeader{
    grid-column: 2 / span 10;
}

.EventListDateHeader {
    grid-column: 2 / span 10;
    margin-top: var(--row-size);
    margin-bottom: var(--margin-size);

}

